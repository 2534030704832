<template>
  <div id="login-page">
    <div class="login-container">
      <div class="login-container__form">
        <div class="header-login">
          <h5 class="ml-3 font-weight-light">Request Password</h5>
        </div>

        <form @submit.prevent="sendEmail">
          <div class="login-container__form-inner">
            <b-form-group>
              <label for="email"> First, we need your email </label>
              <b-form-input
                id="email"
                v-model="email"
                size="sm"
                type="text"
                placeholder="Enter your email"
                :disabled="loading"
              />
            </b-form-group>

            <div class="buttons mb-4">
              <button
                type="submit"
                class="btn btn-sm btn-pompe-primary login-button mt-2 mb-2"
                :loading="loading"
                :disabled="loading"
              >
                <span v-if="!loading"> Request Password </span>
                <span v-else>
                  <b-spinner small label="Small Spinner"></b-spinner>
                </span>
              </button>

              <br />

              <small>
                <a
                  href="javascript:void(0)"
                  class="anchor-link"
                  @click="goBack"
                >
                  Go back
                </a>
              </small>
            </div>
          </div>
        </form>
      </div>
    </div>

    <br />
  </div>
</template>

<script>
export default {
  data: () => {
    return {
      email: '',
      loading: false,
    };
  },
  methods: {
    goBack() {
      if (this.$cookies.get("token")) {
        this.$router.push({ name: 'profilePage' });
        return;
      }

      this.$router.push({ name: 'login' }); 
    },
    sendEmail() {
      this.loading = true;

      const baseUrl = process.env.NODE_ENV === 'development' 
        ? 'http://localhost:4000'
        : process.env.VUE_APP_DOMAIN;

      const url = baseUrl + '/web/new-password';

      this.$requestService
        .auth(
          this.$apiEndpoints.passwordRequest + `?email=${this.email}&pageUrl=${url}`,
          {},
          this.handleRequestSuccess,
          this.handleFailed
        )
        .then(() => {
          this.loading = false;
        });
    },
    handleRequestSuccess() {
      this.$vToastify.success({
        title: "Request Success",
        body: "Your password has been requested, please check your email",
      });

      if (this.$cookies.get("token")) {
        this.$router.push({ name: 'profilePage' });
      } else {
        this.$router.push({ name: 'login' });
      }
    },
    handleFailed(e) {
      this.$vToastify.error(e.response.data.errors[0].message || 'Please contact administrator');
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/color.scss";

.login-container {
  padding-top: 3em;
  padding-bottom: 2.5em;

  .header-login {
    background-color: $primary-ui;
    color: #fff;
    height: 50px;
    padding: 0.75em 0 1em 0.5em;
  }

  &__form {
    display: center;
    justify-content: start;
    align-content: left;
    border: 1px solid #e4e4e4;
    // box-shadow: 2px 2px 5px #c9c9c9;
    width: 600px;
    margin: auto;
    // background-color: #E4E4E4;

    &-inner {
      width: 400px;
      padding-left: 1.5em;
      padding-top: 1em;
    }

    .login-button {
      width: 200px;
    }

    .links {
      font-size: 13px;
      padding-bottom: 20px;

      .no-account {
        margin-top: 1em;
      }
    }
  }
}
</style>
